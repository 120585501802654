import React from 'react'
import TittleHeader from '../components/TitleHeader'

const Teams = () => {
  return (
    <div>
        <TittleHeader title='About Our Team' btnTitle='Our Team'/>
    </div>
  )
}

export default Teams