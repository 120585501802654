import React from 'react'
import TittleHeader from '../components/TitleHeader'

const RequestInfo = () => {
  return (
    <div>
        <TittleHeader title='What do you want to know about us' btnTitle='Request Info'/>
    </div>
  )
}

export default RequestInfo